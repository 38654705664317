import { config } from '../configuration/config';

export const MODULE_NAME = 'SmartRoute';

export const BBOX_OFFSET = 0.1;
export const SINGLE_ASSET_ZOOM = 18;
export const POWER_LOW = 150; // in kW
export const POWER_MEDIUM = 350; // in kW
export const POWER_HIGH = 750; // in kW

export const Unit = {
    KWH: 'kWh',
    kW: 'kW',
    currency: '€',
    weight: 'kg',
    km: 'km',
    pct: '%',
};

export enum ROUTE {
    ROOT_ROUTE = '/',
    SEARCH_ROUTE = '/search',
    DETAILS_ROOT_ROUTE = '/details',
    CLUSTER_ROUTE = '/details/cluster',
    DETAILS_ROUTE = '/details/:id',
}

export const STORE_BASE_PATH = `${config.serviceName}/`;

export const popperModifier = {
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [0, 10],
            },
        },
    ],
};

export const HereSettings = {
    apikey: config.backend.MAP_SERVICE_API_KEY,
};

export const MarketplaceSettings = {
    URL: config.backend.MARKETPLACE_SERVICE,
    productId: 'man-sku00000085',
    defaultLevel: '10',
};

export const API = {
    serviceName: 'smart-route-web-api',
    version: {
        v1: 'v1',
        latest: 'v1',
    },
    endpoints: {
        assetData: '/asset-data',
        route: '/route',
        accountSubscription: '/subscription/account',
        permittedAssets: '/permitted-assets',
        accountGroups: '/tags/groups',
        chargePoints: '/charging-station/list',
        chargeStation: '/charging-station/',
    },
    parameters: {
        limit: 1000,
    },
};

export const BASE_URL = `${config.backend.BACKEND_DOMAIN}/${API.serviceName}/${API.version.latest}`;
