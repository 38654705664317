import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import appReducer from '../../store/app/appSlice';
import { facadeApi } from '../../store/facade/facadeApi';
import filterReducer from '../../store/filter/filterSlice';
import filteredStationsReducer from '../../store/filteredStations/filteredStationsSlice';
import langReducer from '../../store/lang/langSlice';
import loginReducer from '../../store/login/loginSlice';
import tokenReducer from '../../store/tokenHandling/tokenHandlingSlice';
import widgetReducer from '../../store/widget/widgetSlice';

const rootReducer = combineReducers({
    app: appReducer,
    lang: langReducer,
    login: loginReducer,
    filter: filterReducer,
    tokenHandling: tokenReducer,
    widget: widgetReducer,
    filteredStations: filteredStationsReducer,
    [facadeApi.reducerPath]: facadeApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,

        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(facadeApi.middleware),

        devTools: true,
    });
};
export const store = setupStore();

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
