import { createSelector } from 'reselect';

import { RootState } from '../../configuration/setup/store';

export const selectFilteredStationsState = (state: RootState) => state.filteredStations;

export const selectAllStations = createSelector(selectFilteredStationsState, (state) => state.allStations);
export const selectFilteredStations = createSelector(selectFilteredStationsState, (state) => state.filteredStations);
export const selectIsTreeFilterActive = createSelector(
    selectFilteredStationsState,
    (state) => state.isTreeFilterActive
);
export const selectBbox = createSelector(selectFilteredStationsState, (state) => state.bbox);
