import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';
import { STORE_BASE_PATH } from '../../common/constants';
import { BoundingBox } from '../../common/types';
import { ChargingStation } from '../facade/facadeApi';
import { isStationWithinBbox } from '../widget/actions/renderChargingPoints';

export interface StationState {
    filteredStations: ChargingStation[];
    allStations: ChargingStation[];
    selectedStations: ChargingStation[];
    isTreeFilterActive: boolean;
    bbox?: BoundingBox;
}

const initialState: StationState = {
    filteredStations: [],
    allStations: [],
    selectedStations: [],
    isTreeFilterActive: false,
    bbox: undefined,
};

export const filteredStationsSlice = createSlice({
    name: STORE_BASE_PATH + 'filteredStations',
    initialState,
    reducers: {
        updateFilteredStations: (state: StationState, action: PayloadAction<ChargingStation[]>) => {
            state.filteredStations = action.payload;
        },
        updateSelectedStations: (state: StationState, action: PayloadAction<ChargingStation[]>) => {
            const selectedStations = action.payload;
            state.selectedStations = selectedStations;
        },
        updateAllStations: (state: StationState, action: PayloadAction<ChargingStation[]>) => {
            state.allStations = action.payload;

            let filteredStations = state.allStations;
            if (!isUndefined(state.bbox)) {
                filteredStations = filteredStations.filter((station) => isStationWithinBbox(station, state.bbox!));
            }
            state.filteredStations = filteredStations;
            state.selectedStations = action.payload;
        },
        updateBbox: (state: StationState, action: PayloadAction<BoundingBox>) => {
            state.bbox = action.payload;
        },
        updateIsTreeFilterActive: (state: StationState, action: PayloadAction<boolean>) => {
            state.isTreeFilterActive = action.payload;
        },
        resetFilteredStations: () => initialState,
    },
});

export const {
    updateFilteredStations,
    updateSelectedStations,
    updateAllStations,
    updateBbox,
    updateIsTreeFilterActive,
    resetFilteredStations,
} = filteredStationsSlice.actions;

export default filteredStationsSlice.reducer;
