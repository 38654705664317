import { store } from '../../../configuration/setup/store';
import { updateSelectedAssetId } from '../widgetSlice';

export const SELECT_ASSET_EVENT = 'EVENT_SELECT_ASSET';

type SelectAssetType = {
    id: string;
};

export const selectAssetHandler = (data: SelectAssetType) => {
    store.dispatch(updateSelectedAssetId(data.id));
};
