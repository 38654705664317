import { lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouteObject } from 'react-router-dom';
import { ROUTE } from '../common/constants';
import { FallbackError } from '../components/FallbackError/FallbackError';
import { SuspendedWithSpinner } from '../components/SuspendedWithSpinner/SuspendedWithSpinner';
import { AppLayout } from '../containers/AppLayout/AppLayout';

// Lazy load pages for better performance and automatically split the bundle accordingly
const ChargingStationWidgetLazyloaded = lazy(() => import('../pages/ChargingStationWidget/ChargingStationWidget'));
const ChargingPointDetail = lazy(() => import('../pages/ChargingPointDetails/ChargingPointDetails'));
const ClusterDetail = lazy(() => import('../pages/ChargingPointClusterDetail/ChargingPointClusterDetail'));
const DetailsContainer = lazy(() => import('../pages/ChargingPointsDetailsContainer/ChargingPointsDetailsContainer'));

export const routePaths: string[] = Object.values(ROUTE);

export const routeObjects: RouteObject[] = createRoutesFromElements(
    <Route path={ROUTE.ROOT_ROUTE} element={<AppLayout />} errorElement={<FallbackError />}>
        <Route
            path={ROUTE.SEARCH_ROUTE}
            element={
                <SuspendedWithSpinner>
                    <ChargingStationWidgetLazyloaded />
                </SuspendedWithSpinner>
            }
        />
        <Route path={ROUTE.DETAILS_ROOT_ROUTE} element={<DetailsContainer />}>
            <Route
                path={ROUTE.DETAILS_ROUTE}
                element={
                    <SuspendedWithSpinner>
                        <ChargingPointDetail />
                    </SuspendedWithSpinner>
                }
            />
            <Route
                path={ROUTE.CLUSTER_ROUTE}
                element={
                    <SuspendedWithSpinner>
                        <ClusterDetail />
                    </SuspendedWithSpinner>
                }
            />
        </Route>
    </Route>
);

export const router = createBrowserRouter(routeObjects);
