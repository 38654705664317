import { createAction, createSlice } from '@reduxjs/toolkit';
import { STORE_BASE_PATH } from '../../common/constants';
import { ChargingStation } from '../facade/facadeApi';
import { CenterMapType } from './actions/centerMap';
import { RenderShapeType } from './actions/renderShapes';
import { BoundingBoxPayload } from './actions/setBoundingBox';
import { EventShowNotification } from './actions/showMapNotification';
import { SelectGeofencesType } from './events/selectGeofences';
import { SelectPoisType } from './events/selectPois';
import { CHARGING_STATIONS_SELECTED_EVENT, ChargingStationsSelectedPayload } from './events/selectedChargingStations';
import { MapContext, MapContextType } from './events/setMapContext';

export const widgetActions = {
    widgetLoaded: createAction('EVENT_WIDGET_LOADED'),
    setWidgetId: createAction('EVENT_SET_WIDGET_ID'),
    centerMap: createAction<CenterMapType>('EVENT_CENTER_MAP'),
    setVisibleWidgets: createAction('EVENT_SET_VISIBLE_WIDGETS'),
    centerActiveAsset: createAction('EVENT_CENTER_ACTIVE_ASSET'),
    setBoundingBox: createAction<BoundingBoxPayload>('EVENT_SET_BOUNDING_BOX'),
    renderShapes: createAction<RenderShapeType>('EVENT_RENDER_SHAPE'),
    renderChargingPoints: createAction<any>('EVENT_RENDER_CHARGING_STATIONS'),
    selectChargingPoints: createAction<ChargingStationsSelectedPayload>(CHARGING_STATIONS_SELECTED_EVENT),
    addStationToRoute: createAction<ChargingStation>('EVENT_ADD_ROUTE_STATION'),
    showMapNotification: createAction<EventShowNotification>('EVENT_SHOW_NOTIFICATION'),
};

export interface WidgetState {
    selectedAssetId: string | null;
    widgetId?: string;
    isVisible: boolean;
    mapContext: MapContextType;
    selectedPois: SelectPoisType;
    selectedGeofences: SelectGeofencesType;
    selectedChargingStations: string[];
}

const initialState: WidgetState = {
    selectedAssetId: null,
    isVisible: false,
    mapContext: MapContext.MAP_CONTEXT_DEFAULT,
    selectedPois: [],
    selectedGeofences: [],
    selectedChargingStations: [],
};

export const widgetSlice = createSlice({
    name: `${STORE_BASE_PATH}widget`,
    initialState,
    reducers: {
        updateSelectedAssetId: (state, action) => {
            state.selectedAssetId = action.payload;
        },
        updateWidgetId: (state, action) => {
            state.widgetId = action.payload;
        },
        updateWidgetVisibility: (state, action) => {
            state.isVisible = action.payload;
        },
        updateMapContext: (state, action) => {
            state.mapContext = action.payload;
        },
        updateSelectedPois: (state, action) => {
            state.selectedPois = action.payload;
        },
        updateSelectedGeofences: (state, action) => {
            state.selectedGeofences = action.payload;
        },
        updateSelectedChargingStations: (state, action) => {
            state.selectedChargingStations = action.payload;
        },
    },
});

export const {
    updateSelectedAssetId,
    updateWidgetId,
    updateWidgetVisibility,
    updateMapContext,
    updateSelectedPois,
    updateSelectedGeofences,
    updateSelectedChargingStations,
} = widgetSlice.actions;

export default widgetSlice.reducer;
