import { isUndefined } from 'lodash/fp';
import { Coordinates as CoordinatesApi } from '../store/facade/facadeApi';
import { DEFAULT_LOCALE } from '../store/lang/lang';
import { Coordinates, Coords } from './types';

export const coordinatesToCoords = (position: Coordinates | CoordinatesApi): Coords => ({
    lat: position.latitude,
    lng: position.longitude,
});

export const coordsToCoordinates = (position: Coords): Coordinates => ({
    latitude: position.lat,
    longitude: position.lng,
});

export const minutesToHoursFormatted = (unit: string, value: string) => {
    switch (unit) {
        case 'h':
            const hours = Math.floor(Number(value) / 60);
            const minutes = Number(value) % 60;
            const h = String(hours).padStart(2, '0');
            const m = String(minutes).padStart(2, '0');
            return `${h}:${m}`;
        case 'kWh':
        case 'km':
            return value;
    }
};

export const convertISOStringToLocalFormat = (ISOString: string, locale: string | undefined) => {
    const formatedDate = new Date(ISOString).toLocaleString(locale ?? DEFAULT_LOCALE, {
        hour12: false,
    });
    return formatedDate;
};

export const formatAddress = (address?: string, postalCode?: string, city?: string) =>
    [city, address, postalCode].filter((it) => !isUndefined(it)).join(', ');
