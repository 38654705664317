import { store } from '../../../configuration/setup/store';
import { sendMessage } from '../widgetServices';
import { updateSelectedChargingStations, widgetActions } from '../widgetSlice';

export const CHARGING_STATIONS_SELECTED_EVENT = 'EVENT_MAP_CHARGING_STATIONS_SELECTED';

export interface ChargingStationsSelectedPayload {
    ids: string[];
}

export const chargingStationsSelectedHandler = (data: ChargingStationsSelectedPayload) => {
    if (data.ids.length > 0) {
        store.dispatch(updateSelectedChargingStations(data.ids));
    }
};

export const selectChargingStationDetails = (ids: string[]) => sendMessage(widgetActions.selectChargingPoints({ ids }));
