import { inRange } from 'lodash';
import { BoundingBox, Coords } from '../../../common/types';
import { coordinatesToCoords } from '../../../common/utils';
import { store } from '../../../configuration/setup/store';
import { ChargingStation, Coordinates } from '../../facade/facadeApi';
import { updateFilteredStations } from '../../filteredStations/filteredStationsSlice';
import { sendMessage } from '../widgetServices';
import { widgetActions } from '../widgetSlice';
import { selectAllStations } from '../../filteredStations/filteredStationsSelector';

export class MapChargingStation {
    constructor(
        public id: string,
        public data?: {
            enableContextMenu?: boolean;
            contextMenuMarkerName?: string;
            markerType?: string;
            lat?: number;
            lng?: number;
        },
        public markerProps?: {
            name?: string;
        },
        public position?: Coords
    ) {}
}

export class MapStationBuilder {
    private id!: string;
    private name!: string;
    private location!: Coordinates;
    private enableContextMenu!: boolean;
    private contextMenuMarkerName!: string;
    private markerType!: string;

    constructor() {
        this.enableContextMenu = true;
        this.contextMenuMarkerName = 'ChargingStation ContextMenu';
        this.markerType = 'custommarker';
    }

    withId(id: string) {
        this.id = id;
        return this;
    }

    withName(name: string) {
        this.name = name;
        return this;
    }

    withLocation(location: Coordinates) {
        this.location = location;
        return this;
    }

    build(): MapChargingStation {
        const data = {
            enableContextMenu: this.enableContextMenu,
            contextMenuMarkerName: this.contextMenuMarkerName,
            markerType: this.markerType,
            lat: this.location.latitude,
            lng: this.location.longitude,
        };

        const markerProps = {
            name: this.name,
        };

        const position = coordinatesToCoords(this.location);

        return new MapChargingStation(this.id, data, markerProps, position);
    }
}

export const renderChargingPoints = (stations: MapChargingStation[]) => {
    sendMessage(widgetActions.renderChargingPoints({ chargingStations: stations }));
};

export const renderChargingPointsWithStations = (stations: ChargingStation[]) => {
    const mapStations = stations.map((station) =>
        new MapStationBuilder().withId(station?.id).withName(station?.name).withLocation(station?.location).build()
    );
    renderChargingPoints(mapStations);
};

export const renderChargingPointsWithBbox = (bbox: BoundingBox) => {
    const stations = selectAllStations(store.getState());
    const stationsWithinBbox = stations.filter((station) => isStationWithinBbox(station, bbox));

    store.dispatch(updateFilteredStations(stationsWithinBbox));

    const mapStations = stationsWithinBbox.map((station) =>
        new MapStationBuilder().withId(station?.id).withName(station?.name).withLocation(station?.location).build()
    );

    renderChargingPoints(mapStations);
};

export const isStationWithinBbox = (station: ChargingStation, bbox: BoundingBox) => {
    const lat = station.location.latitude;
    const lng = station.location.longitude;

    const maxLat = bbox.topLeft.lat;
    const minLat = bbox.bottomRight.lat;
    const maxLng = bbox.bottomRight.lng;
    const minLng = bbox.topLeft.lng;

    return inRange(lat, minLat, maxLat) && inRange(lng, minLng, maxLng);
};
