import { trace } from '../../configuration/setup/trace';
import { centerActiveAssetHandler, CENTER_ACTIVE_ASSET_EVENT } from './events/centerActiveAsset';
import { mapChangedHandler, MAP_CHANGED_EVENT } from './events/mapChanged';
import { selectAssetHandler, SELECT_ASSET_EVENT } from './events/selectAsset';
import { setMapContextHandler, SET_MAP_CONTEXT_EVENT } from './events/setMapContext';
import { setWidgetIdHandler, SET_WIDGET_ID_EVENT } from './events/setWidgetId';
import { visibleWidgetsHandler, VISIBLE_WIDGETS_EVENT } from './events/visibleWidgets';
import { selectGeofencesHandler, SELECT_GEOFENCES_EVENT } from './events/selectGeofences';
import { selectPoisHandler, SELECT_POIS_EVENT } from './events/selectPois';
import { chargingStationsSelectedHandler, CHARGING_STATIONS_SELECTED_EVENT } from './events/selectedChargingStations';
import { RENDER_STATIONS_EVENT, renderStationsHandler } from './events/renderStations';

const EVENT_PREFIX = 'EVENT';

export const sendMessage = (message: any) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

export const sendWidgetMessage = (targetOrigin: string, message: any) => {
    if (!window.top) {
        return;
    }
    const frames = window.top.frames;
    const windows = Object.values(frames).slice(1);
    windows.forEach((window) => window.postMessage(message, targetOrigin));
};

export const processWidgetAction = (event: MessageEvent) => {
    const actionType = event.data.type;
    if (!actionType || !actionType.startsWith(EVENT_PREFIX)) {
        return;
    }
    trace.debug('Widget event:', event.data);

    const payload = event.data.payload;

    switch (actionType) {
        case SELECT_ASSET_EVENT:
            selectAssetHandler(payload);
            break;
        case SET_WIDGET_ID_EVENT:
            setWidgetIdHandler(payload);
            break;
        case VISIBLE_WIDGETS_EVENT:
            visibleWidgetsHandler(payload);
            break;
        case CENTER_ACTIVE_ASSET_EVENT:
            centerActiveAssetHandler(payload);
            break;
        case MAP_CHANGED_EVENT:
            mapChangedHandler(payload);
            break;
        case SET_MAP_CONTEXT_EVENT:
            setMapContextHandler(payload);
            break;
        case SELECT_POIS_EVENT:
            selectPoisHandler(payload);
            break;
        case SELECT_GEOFENCES_EVENT:
            selectGeofencesHandler(payload);
            break;
        case CHARGING_STATIONS_SELECTED_EVENT:
            chargingStationsSelectedHandler(payload);
            break;
        case RENDER_STATIONS_EVENT:
            renderStationsHandler(payload);
            break;
        default:
            trace.warn(`Action of type "${actionType}" is not a valid widget action.`);
    }
};
