import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_BASE_PATH } from '../../common/constants';

interface ChargingPower {
    low: boolean;
    medium: boolean;
    high: boolean;
}

interface ChargeGrading {
    truckReady: boolean;
    limited: boolean;
    unspecified: boolean;
}

export interface FilterState {
    chargingEmsp: boolean;
    power: ChargingPower;
    grading: ChargeGrading;
}

const initialState: FilterState = {
    chargingEmsp: true,
    power: {
        low: false,
        medium: true,
        high: true,
    },
    grading: {
        truckReady: true,
        limited: false,
        unspecified: false,
    },
};

export const filterSlice = createSlice({
    name: STORE_BASE_PATH + 'filter',
    initialState,
    reducers: {
        changeChargingEmsp: (state: FilterState, action: PayloadAction<boolean>) => {
            state.chargingEmsp = action.payload;
        },
        changeChargingPowerLow: (state: FilterState, action: PayloadAction<boolean>) => {
            state.power.low = action.payload;
        },
        changeChargingPowerMedium: (state: FilterState, action: PayloadAction<boolean>) => {
            state.power.medium = action.payload;
        },
        changeChargingPowerHigh: (state: FilterState, action: PayloadAction<boolean>) => {
            state.power.high = action.payload;
        },
        changeGradingTruckReady: (state: FilterState, action: PayloadAction<boolean>) => {
            state.grading.truckReady = action.payload;
        },
        changeGradingLimited: (state: FilterState, action: PayloadAction<boolean>) => {
            state.grading.limited = action.payload;
        },
        changeGradingUnspecified: (state: FilterState, action: PayloadAction<boolean>) => {
            state.grading.unspecified = action.payload;
        },
    },
});

export const {
    changeChargingEmsp,
    changeChargingPowerLow,
    changeChargingPowerMedium,
    changeChargingPowerHigh,
    changeGradingLimited,
    changeGradingTruckReady,
    changeGradingUnspecified,
} = filterSlice.actions;

export default filterSlice.reducer;
